import React from "react"

import { createLocalLink } from "../../utils"
import { Link } from "gatsby"
import SEO from "../seo"

if (typeof window !== "undefined") {
	require("smooth-scroll")('a[href*="#"]', {
		speed: 500,
		speedAsDuration: true,
		easing: 'easeInOutQuad',
	});
}

const HomeBanner = ({ heroImg, title, subtitle, cta, description }) => {

	return (
		<header id="banner" className="banner banner--home banner--overlay">
			<SEO title={`Geoff Morstock | Full Stack Web Developer`} titleIsHome={true} description={description} />
			<img className="banner__img" src={heroImg.sourceUrl} alt="" /> 
			<div className="constrained banner__content">
				<h1>
					<span className="banner__ttl">{title}</span>
					<span className="banner__sub" dangerouslySetInnerHTML={{ __html: subtitle }}></span>
				</h1>
				<Link className="btn btn--cta" to={`/${createLocalLink(cta.url)}`}>{cta.title}</Link>
			</div>
		</header>
	)
}

export default HomeBanner;