import React from "react"
import { Link } from "gatsby"
import { createLocalLink } from "../utils"

const Services = ({ title, text, list, link }) => {
	return (
		<section className="services white">
			<div id="services" className="link"></div>
			<div className="constrained">
				<header>
					<h2>{title}</h2>
					<div className="services__intro" dangerouslySetInnerHTML={{ __html: text }}>
					</div>
				</header>
				<div className="services__main">

					{list.map((serviceBlock, index) => (
						<div key={index} className="services__block">
							<div className="services__block-inner">
								<img className="service__watermark" src={serviceBlock.watermark.mediaItemUrl} alt="" />
								<div className="services__content">
									<header>
										<h3>{serviceBlock.title}</h3>
									</header>
									<div className="services__text" dangerouslySetInnerHTML={{ __html: serviceBlock.text }}>
									</div>
								</div>
								{serviceBlock.cta ? <Link className="btn btn--cta" href={serviceBlock.cta.url}>{serviceBlock.cta.title}</Link> : null}
							</div>
						</div>
					))}



				</div>
				<footer>
					<Link className="btn btn--cta" to={`/${createLocalLink(link.url)}`}>{link.title}</Link>
				</footer>
			</div>
		</section>
	)
}
export default Services;
