import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

import HomeBanner from "../components/banners/homeBanner"
import Preview from "../components/preview"
import Services from "../components/services"

const IndexPage = () => {

	const data = useStaticQuery(graphql`
		query FrontQuery {
			wpgraphql {
				page(id: "cGFnZTo4") {
					id
					featuredImage {
						node {
							sourceUrl(size: LARGE)
							srcSet(size: LARGE)
						}
					}
					acfPageFront {
						frontSeoDescription
						bannerTitle
						bannerSubtitle
						bannerCta {
							title
							url
						}
						featuredTitle
						featuredWork {
							... on WPGraphql_Project {
								id
								title(format: RENDERED)
								uri
								featuredImage {
									node {
										sourceUrl(size: MEDIUM_LARGE)
									}
								}
								acfWork {
									  previewBlockTitle
								}
							}
						}
						featuredLink {
							url
							title
						}
						serviceTitle
						serviceContent
						serviceLink {
							title
							url
						}
						serviceList {
							text
							title
							cta {
								title
								url
							}
							watermark {
								mediaItemUrl
							}
						}
					}
				}
			}
		}
	`)

	const frontData = data.wpgraphql.page;
	const acfFrontData = frontData.acfPageFront;

	return (
		<Layout>
			<SEO title="Home" />
			<article>
				<HomeBanner 
					heroImg={frontData.featuredImage}
					title={acfFrontData.bannerTitle}
					subtitle={acfFrontData.bannerSubtitle}
					cta={acfFrontData.bannerCta}
					description={acfFrontData.frontSeoDescription}
				/>
				<Preview 
					title={acfFrontData.featuredTitle}
					projects={acfFrontData.featuredWork}
					link={acfFrontData.featuredLink}
				/>
				<Services 
					title={acfFrontData.serviceTitle}
					text={acfFrontData.serviceContent}
					list={acfFrontData.serviceList}
					link={acfFrontData.serviceLink}
				/>
			</article>
		</Layout>
	)
}

export default IndexPage
