import React from "react"
import { Link } from "gatsby"
import { createLocalLink } from "../utils"

const Preview = ({ title, projects, link }) => {
	return (
		<section className="preview light">
			<div id="preview" className="link"></div>
			<div className="constrained">
				<header>
					<h2>{title}</h2>
				</header>
			</div>
			<div className="preview__main preview__main--archive">
				{projects.map(project => (
					<div key={project.id} className="preview__block filter-industry-all">
						<div className="preview__inner">
							<div className="preview__helper"></div>
							<div className="preview__image">
								<img src={project.featuredImage.node.sourceUrl} alt={project.title + ' Screenshot'} />
							</div>
							<Link to={`/${project.uri}`} className="preview__content">
								<h3 dangerouslySetInnerHTML={{ __html: ( project.acfWork.previewBlockTitle !== '' ) ? project.acfWork.previewBlockTitle : project.title }}></h3>
							</Link>
						</div>
					</div>
				))}
			</div>
			<footer>
				<Link className="btn btn--cta" to={`/${createLocalLink(link.url)}`}>{link.title}</Link>
			</footer>
		</section>
	)
}
export default Preview;